.copycanvas {
    position: fixed;
    top: 0;
    left: 0;
}

.processingUI {
    top: calc(50% - 30px);
    position: fixed;
    left: calc(50% - 150px);
    background: #FFFFFF;
    border-radius: 15px;
    width: 300px;
    padding: 30px;
    font-family: "ProximaNova-Bold";
    font-size: 16px;
    color: $COLOR_PURPLE;
    line-height: 20px;
    text-transform: uppercase;
    margin-left: 30px;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.15s;
    z-index: 100001;
}

body {
    &:after {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 10000;
        content: '';
        transition: opacity 0.15s;
        background: rgba(#000000, 0.7);
        pointer-events: none;
        opacity: 0;
    }
}

html.processing {
    pointer-events: none !important;
    body {
        &:after {
            opacity: 1;
        }
    }
    .processingUI {
        opacity: 1;
    }
}

.CanvasRenderer {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: absolute;
    align-items: center;
    left: calc(15px + 480px + 15px);
    top: 15px;
    right: 15px;
    @media (min-width: $BREAKPOINT_DESKTOP_MARGIN) {
        left: calc(50px + 480px + 50px);
        top: 50px;
        right: 50px;
    }
    transition: opacity 0.5s, visibility 0s 0.5s;
    opacity: 0;
    visibility: hidden;

    & > * {
        width: 50%;
    }

    .SourceImageWrapper {
        display: none;
    }

    img {
        outline: none;
        border: none;
        opacity: 0;
    }

}

.SourceCanvasWrapper {
    //width: 100%;
    margin-top: 50px;
    display: none;

    canvas {
        width: 100%;
        object-fit: contain;
    }
}

.MainCanvasWrapper {
    //width: 100%;
    canvas {
        //width: 100% !important;
        height: 100%;
        object-fit: contain;
    }
}

#sourceImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top center;
}

#SVGSymbol, #SVGOverlay {
    display: none;
}