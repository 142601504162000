// Inlcude and define global styles here
// -- fonts, variables, etc.
@import "styles/mixins/eases";
@import "styles/variables";
@import "styles/mixins";
@import "styles/fonts.css";
@import "components/CanvasRenderer";
@import "tweakpane-image-plugin-main/src/sass/plugin";

html,
body {
    font-size: 22px;
    font-family: $FONT;
    width: 100%;
    height: 100%;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'ProximaNova-Regular';
    width: 100%;
    height: 100%;
    background-color: $COLOR_BG;
    color: $COLOR_BLACK;
    overflow-y: scroll;
}

:root {
    --tp-base-background-color: hsla(230, 0%, 100%, 1.00);
    --tp-base-shadow-color: hsla(0, 0%, 100%, 0.00);
    --tp-button-background-color: hsla(230, 0%, 100%, 1.00);
    --tp-button-background-color-active: hsla(0, 0%, 85%, 1.00);
    --tp-button-background-color-focus: hsla(0, 0%, 90%, 1.00);
    --tp-button-background-color-hover: hsla(0, 0%, 95%, 1.00);
    --tp-button-foreground-color: hsla(18, 100%, 47%, 1.00);
    --tp-container-background-color: hsla(230, 15%, 30%, 0.20);
    --tp-container-background-color-active: hsla(230, 15%, 30%, 0.32);
    --tp-container-background-color-focus: hsla(230, 15%, 30%, 0.28);
    --tp-container-background-color-hover: hsla(230, 15%, 30%, 0.24);
    --tp-container-foreground-color: hsla(230, 10%, 30%, 1.00);
    --tp-groove-foreground-color: hsla(230, 15%, 30%, 0.10);
    --tp-input-background-color: hsla(230, 0%, 100%, 0.10);
    --tp-input-background-color-active: hsla(230, 15%, 30%, 0.22);
    --tp-input-background-color-focus: hsla(230, 15%, 30%, 0.18);
    --tp-input-background-color-hover: hsla(230, 15%, 30%, 0.14);
    --tp-input-foreground-color: hsla(18, 100%, 47%, 1.00);
    --tp-label-foreground-color: hsla(230, 10%, 30%, 0.70);
    --tp-monitor-background-color: hsla(230, 15%, 30%, 0.10);
    --tp-monitor-foreground-color: hsla(230, 10%, 30%, 0.50);
}

.second-pane {
    .active-button-style-0 {
        & > div > div:nth-child(5) > div > div:nth-child(1) button {
                border: 2px solid $COLOR_ORANGE !important;
        }
    }
    .active-button-style-1 {
        & > div > div:nth-child(5) > div > div:nth-child(2) button {
                border: 2px solid $COLOR_ORANGE !important;
        }
    }
    .active-button-style-2 {
        & > div > div:nth-child(5) > div > div:nth-child(3) button {
                border: 2px solid $COLOR_ORANGE !important;
        }
    }
    .active-button-style-3 {
        & > div > div:nth-child(5) > div > div:nth-child(4) button {
                border: 2px solid $COLOR_ORANGE !important;
        }
    }
}

.pane-controls-inject {
    .tp-txtv_k {
        display: none;
    }

    .tp-btnv_b {
        border: 1px solid $COLOR_PURPLE;
        border-radius: 20px;
        height: 30px;

        .tp-btnv_t {
            font-family: "ProximaNova-Bold";
            font-size: 12px;
            color: $COLOR_PURPLE;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &.second-pane > div > div > div.tp-lblv.tp-v-fst.tp-v-vfst {
        margin-right: 0;
    }
    &.second-pane > div > div > div:nth-child(5) {
        &:before {
            font-family: "ProximaNova-Bold";
            font-size: 16px;
            color: #000000;
            line-height: 24px;
            padding-left: 8px;
            content: 'Select color combination'
        }

        .tp-brkv.tp-fldv_c {
            justify-content: space-between;
        }
        .tp-lblv {
            margin: 0;
            width: auto;
            margin-top: 10px;
            margin-bottom: 26px;

            &:nth-child(1) .tp-btnv {
                background: url("data:image/svg+xml,%3Csvg width='43' height='26' viewBox='0 0 43 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-19 -11)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%233A0084' cx='49' cy='24' r='13'/%3E%3Ccircle fill='%23F04800' cx='32' cy='24' r='13'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
            }

            &:nth-child(2) .tp-btnv {
                background: url("data:image/svg+xml,%3Csvg width='43' height='26' viewBox='0 0 43 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-19 -11)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23F04800' cx='49' cy='24' r='13'/%3E%3Ccircle fill='%233A0084' cx='32' cy='24' r='13'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
            }

            &:nth-child(3) .tp-btnv {
                background: url("data:image/svg+xml,%3Csvg width='44' height='28' viewBox='0 0 44 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-19 -10)' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke='%23979797' fill='%23FFF' cx='49' cy='24' r='13'/%3E%3Ccircle fill='%23F04800' cx='32' cy='24' r='13'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
            }

            &:nth-child(4) .tp-btnv {
                background: url("data:image/svg+xml,%3Csvg width='44' height='28' viewBox='0 0 44 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-19 -10)' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke='%23979797' fill='%23FFF' cx='49' cy='24' r='13'/%3E%3Ccircle fill='%233A0084' cx='32' cy='24' r='13'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
            }
        }

        .tp-btnv_b {
            background: transparent !important;
            border: 1px solid #C8C8C8;
            border-radius: 5px;
            height: 47px;
            width: 81px;

            &:hover {
                border: 2px solid #F04800;
            }
        }
    }

    .tp-fldv {
        .tp-fldv_b {
            display: none;
        }

        .tp-fldv_c {
            border: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: row;

            .tp-lblv_v {
                flex-grow: 0;
                width: auto;
            }

            .tp-lblv {
                flex-flow: row;
                justify-content: center;
                margin-top: 0;
                width: 50%;
            }

            .tp-lblv_l {
                width: 100%;
                flex-grow: 1;
                line-height: 16px;
            }
        }
    }

    &.first-pane {
        margin-top: 30px;

        .tp-btnv_b {
            border: 1px solid $COLOR_ORANGE;
            height: 30px;
            width: 208px;
            margin: 0 auto;

            .tp-btnv_t {
                color: $COLOR_ORANGE;
            }
        }
    }

    .tp-sldv_t {
        &:before {
            background-color: #C2C2C2;
            border-radius: 0;
        }

        .tp-sldv_k {
            &:before {
                height: 4px;
                border-radius: 0;
            }

            &:after {
                background: $COLOR_ORANGE;
                border-radius: 10px;
                width: 20px;
                height: 20px;
                background-color: $COLOR_ORANGE;
            }
        }

        &:hover {
            .tp-sldv_k {
                &:after {
                    background-color: $COLOR_PURPLE;
                }
            }
        }
    }

    .tp-ckbv_w {
        border: 1px solid #C8C8C8;
        border-radius: 1px;
    }

    .tp-p2dv_h {
        display: none;
    }

    .tp-p2dpv {
        padding-left: 0;
    }

    .tp-p2dpv_p {
        background: #FFFFFF;
        border: 1px solid #C8C8C8;
        border-radius: 5px;
    }

    .tp-lblv {
        margin-bottom: 16px;
    }

    .tp-lblv_v {
        max-width: none;
        flex-grow: 1;
    }

    .tp-p2dv {
        max-width: 125px;
    }

    .tp-imgv {
        width: 125px;
        height: 125px;
        border: 1px solid #C8C8C8;
        border-radius: 5px;

        img {
            outline: none;
            border: none;
            appearance: none;
            border-style: none;
        }

        IMG[src=''], IMG:not([src]) {
            opacity: 0;
        }
    }

    .tp-lblv_l {
        max-width: 190px;
        font-family: "ProximaNova-Bold";
        font-size: 16px;
        color: #000000;
        line-height: 24px;
    }

    &.second-pane > div > div > div:nth-child(7) > div.tp-lblv_l {
        &:after {
            content: 'Click and drag';
            display: block;
            color: #BBBBBB;
            font-family: 'ProximaNova-Regular';
        }
    }

    &.second-pane > div > div > div.tp-fldv.tp-cntv.tp-fldv-expanded.tp-fldv-cpl.tp-v-lst.tp-v-vlst > div {
        .tp-btnv_b {
            border-color: $COLOR_ORANGE;

            .tp-btnv_t {
                color: $COLOR_ORANGE;
            }
        }
    }

    &.second-pane > div > div > div:nth-child(6) > div {
        width: 80%;

        .tp-lblv {
            justify-content: flex-start;
        }

        .tp-lblv_l {
            max-width: 84px;
        }
    }

    &.second-pane > div > div > div.tp-fldv.tp-cntv.tp-fldv-expanded.tp-fldv-cpl.tp-v-lst.tp-v-vlst > div {
        .tp-lblv {
            margin-bottom: 0;
            margin-top: -3px;
        }
    }

    &.second-pane {
        margin-top: 30px;

        .tp-lblv.tp-v-fst {
            margin-right: 10px;
        }

        .tp-lblv.tp-v-lst {
            margin-left: 10px;
        }
    }
}


.pane {
    position: absolute;
    top: 15px;
    left: 15px;
    background: #FFFFFF;
    border-radius: 15px;
    max-width: 480px;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    align-items: flex-start;
    transition: height 0.5s;
    @media (min-width: $BREAKPOINT_DESKTOP_MARGIN) {
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .pane-page {
        height: auto;
        padding: 30px;
        width: 100%;
        flex-shrink: 0;
        transition: transform 0.5s;
    }

    .pane-header {
        display: flex;
        flex-flow: row;
        align-items: center;

        h1 {
            font-family: "ProximaNova-Bold";
            font-size: 18px;
            color: $COLOR_PURPLE;
            line-height: 20px;
            text-transform: uppercase;
            margin-left: 30px;
        }

        a {
            padding-right: 30px;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    h3 {
        font-family: "ProximaNova-Bold";
        font-size: 22px;
        color: #234A75;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    p {
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        margin-bottom: 16px;
    }

    ul {
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        list-style: none;

        li {
            line-height: 24px;
            padding-left: 15px;
            margin-bottom: 15px;

            &:before {
                content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: $COLOR_PURPLE; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
        }


    }
}

.preview {
    display: flex;
    flex-flow: column;
    position: static;
    left: calc(15px + 480px + 15px);
    margin-top: 800px;
    right: 0;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s, visibility 0s 0s;
    @media (min-width: $BREAKPOINT_DESKTOP_MARGIN) {
        position: absolute;
        margin-top: 0px;
        left: calc(50px + 480px + 50px);
        top: 50px;
    }

    img {
        max-width: 208px;
        max-height: 208px;
        margin-bottom: 22px;
        //width: 100%;
    }

    svg {
        width: 71px;
        height: 9px;
        flex-shrink: 0;
        margin: 0 30px;
        margin-top: 99px;
    }

    div {
        display: flex;
        text-align: center;
        color: $COLOR-WHITE;
        flex-flow: row;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 40px;

        div {
            max-width: 208px;
            display: flex;
            flex-flow: column;
        }
    }
}

html.step-2 {
    .preview {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0s 0.5s;
    }

    .CanvasRenderer {
        opacity: 1;
        transition: opacity 0.5s, visibility 0s 0s;
        visibility: visible;
    }

    .pane-page {
        transform: translateX(-100%);
    }
}

canvas {
    outline: none;
}

text {
    font-family: $FONT;
}


* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-family: $FONT;
}

a,
a:visited,
a:active,
a:hover {
    text-decoration: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
}
.AcceptUI {
    inset: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: opacity 0.2s, visibility 0.2s 02s;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background: rgba(#000000, 0.8);
    .AcceptUI-inner {
        display: flex;
        flex-flow: column;
        flex-grow: 0;
        background: #FFFFFF;
        border-radius: 15px;
        width: 480px;
        padding: 30px;
        font-size: 16px;
        color: $COLOR_PURPLE;
        line-height: 20px;
        text-align: left;
        p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            a {
                color: $COLOR_ORANGE;
            }
        }
        button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-family: inherit;
            outline: none;
            padding: 0;
            background-color: var(--btn-bg);
            color: var(--btn-fg);
            cursor: pointer;
            display: block;
            font-weight: bold;
            line-height: var(--bld-us);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: uppercase;
            border: 1px solid $COLOR_PURPLE;
            height: 30px;
            border-radius: 15px;
            width: 100px;
            margin: 0 auto;
            margin-top: 30px;
            font-size: 12px;
            cursor: pointer;
        }
    }
}
html.accepted {
    .AcceptUI {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
}