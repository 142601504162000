// Import core styles
@import '/node_modules/@tweakpane/core/lib/sass/plugin';

.#{$prefix}-imgv {
	@extend %input;

	cursor: pointer;
	display: grid;
	height: calc(var(--bld-us) * 3);
	overflow: hidden;
	position: relative;

	&.#{$prefix}-v-disabled {
		opacity: 0.5;
	}

	&_input {
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	&_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		pointer-events: none;
		border: 0;
	}
}
